export const drawLineP = (el, list, lineColors, maxCount, codeType = '') => {
  const config = new Proxy(
    { lineColors, lineWidth: 2, code: codeType },
    {
      get: function (obj, prop) {
        // 默认行为是返回属性值
        return obj[prop];
      },
      set: function (obj, prop, value) {
        obj[prop] = value;
        // 表示成功
        return true;
      },
    }
  );
  const ctx = el.getContext('2d');
  let firstTime = 0;
  let lastTime = 0;
  let isClick = false;
  let isDown = false; // 是否按下
  let currentIndex = -1;
  let pointMap = new Map();
  const downPoint = { x: 0, y: 0 };
  const maxCounts = maxCount;
  let moveLineInitData = null;
  // 起点
  el.onmousedown = (e) => {
    firstTime = Date.now();
    isDown = true;
    pointMap.clear();
    if (e.type !== 'mousedown') {
      return;
    }
    downPoint.x = e.pageX - Math.floor(el.getBoundingClientRect().left);
    downPoint.y = e.pageY - Math.floor(el.getBoundingClientRect().top);
    if (currentIndex !== -1) {
      moveLineInitData = JSON.parse(JSON.stringify(list[currentIndex]));
    }
    console.log(downPoint, 'downPoint');
    console.log(moveLineInitData, 'moveLineInitData');
  };
  // 移动
  el.onmousemove = (e) => {
    const x = e.pageX - Math.floor(el.getBoundingClientRect().left);
    const y = e.pageY - Math.floor(el.getBoundingClientRect().top);
    if (isDown) {
      pointMap.set(x, y);
      if (
        currentIndex !== -1 &&
        moveLineInitData &&
        Reflect.has(moveLineInitData, 'x') &&
        Reflect.has(moveLineInitData, 'ox')
      ) {
        const line = list[currentIndex];
        const movePoint = {
          x: x - downPoint.x,
          y: y - downPoint.y,
        };
        console.log(x, y, 'move');
        if (
          Math.abs(downPoint.x - moveLineInitData.x) <= 4 &&
          Math.abs(downPoint.y - moveLineInitData.y) <= 4
        ) {
          // 起点
          line.x = moveLineInitData.x + movePoint.x;
          line.y = moveLineInitData.y + movePoint.y;
        } else if (
          Math.abs(downPoint.x - moveLineInitData.ox) <= 4 &&
          Math.abs(downPoint.y - moveLineInitData.oy) <= 4
        ) {
          // 终点
          line.ox = moveLineInitData.ox + movePoint.x;
          line.oy = moveLineInitData.oy + movePoint.y;
        } else {
          line.x = moveLineInitData.x + movePoint.x;
          line.y = moveLineInitData.y + movePoint.y;
          line.ox = moveLineInitData.ox + movePoint.x;
          line.oy = moveLineInitData.oy + movePoint.y;
        }
        reDraw();
        drawArc(line);
      }
    }
  };
  // 终点
  el.onmouseup = (e) => {
    const x = e.pageX - Math.floor(el.getBoundingClientRect().left);
    const y = e.pageY - Math.floor(el.getBoundingClientRect().top);
    lastTime = Date.now();
    isClick = lastTime - firstTime < 200;
    if (isDown && !isClick && currentIndex === -1) {
      if (!pointMap.size || (downPoint && downPoint.x === x)) {
        return;
      }
      if (list.length >= maxCounts) {
        return;
      }
      if (!codeType) {
        return;
      }
      list.push({
        ...downPoint,
        ox: x,
        oy: y,
        lineColors: config.lineColors,
        code: config.code,
      });
      reDraw();
    }
    isDown = false;
  };
  el.onclick = (e) => {
    const x = e.pageX - Math.floor(el.getBoundingClientRect().left);
    const y = e.pageY - Math.floor(el.getBoundingClientRect().top);
    if (isClick) {
      if (currentIndex !== -1) {
        reDraw();
      }
      getSelectedLineIndex(x, y);
    }
  };
  const getLineL = (p0, p1) => {
    return Math.abs(
      Math.sqrt(Math.pow(p1.x - p0.x, 2) + Math.pow(p1.y - p0.y, 2))
    );
  };
  // 画端点
  const drawArc = (line) => {
    const { x, y, ox, oy } = line;
    ctx.beginPath();
    const R = 2 * Math.PI;
    ctx.arc(x, y, 4, 0, R);
    ctx.arc(ox, oy, 4, 0, R);
    ctx.strokeStyle = 'transparent';
    ctx.fillStyle = line.lineColors || lineColors;
    ctx.fill();
    ctx.stroke();
  };
  const getSelectedLineIndex = (x, y) => {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (!item) {
        continue;
      }
      const p0 = { x: item.x, y: item.y };
      const p1 = { x: item.ox, y: item.oy };

      const p2 = { x, y };
      const k = getLineL(p0, p1);
      const k0 = getLineL(p2, p0);
      const k1 = getLineL(p2, p1);

      const difference = k0 + k1 - k;
      if (
        difference <= 0.1 &&
        Math.min(p0.x, p1.x) <= x &&
        x <= Math.max(p0.x, p1.x) &&
        Math.min(p0.y, p1.y) <= y &&
        y <= Math.max(p0.y, p1.y)
      ) {
        currentIndex = i;
        drawArc(item);
        break;
      } else {
        currentIndex = -1;
        reDraw();
      }
    }
  };
  const reDraw = () => {
    ctx.clearRect(
      0,
      0,
      el.getBoundingClientRect().width,
      el.getBoundingClientRect().height
    );
    list.forEach((item) => {
      if (!item.ox) {
        return;
      }
      ctx.beginPath();
      ctx.strokeStyle = item.lineColors || config.lineColors || 'black';
      ctx.lineWidth = config.lineWidth;
      item.code = item.code || config.code;
      ctx.moveTo(item.x, item.y);
      ctx.lineTo(item.ox, item.oy);
      ctx.stroke();
    });
  };
  if (list.length) {
    reDraw();
  }
  document.onkeydown = (e) => {
    if (e.code === 'Delete') {
      if (currentIndex !== -1) {
        list.splice(currentIndex, 1);
        reDraw();
      }
      currentIndex = -1;
    }
  };
  return {
    config: (options) => {
      Object.assign(config, options);
    },
  };
};
